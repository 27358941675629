import { SimilarListing } from './listing';

export interface ExclusiveListingInfo {
  created_at?: string | null;
  display_address_human_readable?: string;
  display_street_name?: string;
  end_time?: string | null;
  exclusives_listing_price_cents?: number | null;
  fixed_pricing_strategy?: boolean | null;
  fixed_pricing_enrolled_at?: Date | null;
  has_seller_provided_photos?: boolean | null;
  local_area_map_image?: string;
  matterport_id?: string | null;
  max_coe_date?: string;
  mls_listing_price_cents?: number | null;
  ongoing_and_upcoming_open_houses?: OpenHouse[];
  similar_listings?: SimilarListing[];
  start_time?: string | null;
  self_tourable?: boolean;
  seller_offer_experiment?: boolean;
  seller_owned_with_opendoor_set_price?: boolean;
  seller_owned_vacant_status?: VacantStatus | null;
  state: ExclusiveListingState;
  previous_listing_photos_opt_out?: boolean | null;
  seller_disclosures_download_url?: string;
  zillow_url?: string | null;
  ballpark_price_range?: {
    low_estimate_cents: number;
    high_estimate_cents: number;
  } | null;
  /**
   * @deprecated
   */
  display_slug?: string;
  listing_type?: ListingType;
  roam_mortgage_rate?: number;
  roam_mortgage_rate_confirmed?: boolean;
  exclusives_pdp_price_cents_offer_ranges: {
    low_offer: number;
    okay_offer: number;
    good_offer: number;
    great_offer: number;
    market_rate: number;
    previous_good_offer?: number;
  };
  prelim_offer_price_range?: {
    lower_bound_cents: number;
    upper_bound_cents: number;
  };
  tour_request_options?: ExclusiveTourRequestOptions;
}

export enum VacantStatus {
  BLANKET_APPROVAL = 'blanket_approval',
  STANDARD_APPROVAL = 'standard_approval',
}

export const ListingType = {
  // Home is owned by the seller, sold exclusively on EL
  SELLER_OWNED_EXCLUSIVE: 'seller_owned_exclusive',
  // Home is owned by OD, sold exclusively on EL
  OD_OWNED_EXCLUSIVE: 'od_owned_exclusive',
  // Home is owned by OD, sold on EL and on the MLS
  OOMLS: 'od_owned_publicly_listed',
} as const;

export type ListingType = (typeof ListingType)[keyof typeof ListingType];

export type OpenHouseReservation = {
  attended: boolean | null;
  customer_uuid: string;
  email: string;
  first_name: string;
  is_agent: boolean;
  last_name: string;
  primary_phone: string;
  reservation_id: string;
};

export interface OpenHouse {
  start_time: string;
  end_time: string;
  uuid: string;
  reservations?: OpenHouseReservation[];
}

export const ExclusiveListingState = {
  Listed: 'el_listed',
  InContract: 'el_in_contract',
  Sold: 'el_sold',
  Setup: 'el_setup',
  Expired: 'el_expired',
  AwaitingSellerCommitment: 'el_awaiting_seller_commitment',
} as const;

export type ExclusiveListingState =
  (typeof ExclusiveListingState)[keyof typeof ExclusiveListingState];

export interface ExclusiveTourRequestOptions {
  schedule: Record<string, Record<string, boolean>>;
  overrides_deny: string[];
  archived_overrides: string[];
}
