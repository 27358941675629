import { css } from '@emotion/react';
import { Box, Flex, Icon, Link } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ArrowLeft from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowLeft';
import ArrowRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowRight';
import { novo } from '@opendoor/bricks/theme';
import { useRouter } from 'next/router';

import { LandingPageV2Props } from 'cms';
import landingPageV2EntryLookup from 'cms/entries/landingPageV2';
import RichTextRenderer from 'cms/renderer';

import { CacheSafeGetServerSideProps } from 'declarations/shared/serverSideProps';

import { getPaginationForData } from 'helpers/pagination';

import LandingPageV2Layout, {
  getLandingpageV2ServerSideProps,
} from 'pages/shared/LandingPageV2Layout';

const paginationStyles = css`
  & {
    align-items: center;
    background-color: ${novo.colors.warmgrey100};
    border-radius: ${novo.radii.roundedSquare};
    color: ${novo.colors.warmgrey950};
    cursor: pointer;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    transition: transform 0.3s cubic-bezier(0.55, 0, 0.15, 1),
      opacity 0.3s cubic-bezier(0.55, 0, 0.15, 1) 0.15s;
    width: 40px;
  }
  &:active {
    transform: scale(0.8);
  }
`;

export const getServerSideProps: CacheSafeGetServerSideProps<LandingPageV2Props> = async (
  context,
) => getLandingpageV2ServerSideProps(context);

// don't care about 'any' here because LandingPage has proper typing
const LandingPageWithContext = (props: LandingPageV2Props) => {
  const router = useRouter();
  const pageNumQuery = parseInt(router.query.page as string);

  const { previousPage, nextPage, currentPage, lastPage, showPagination } = getPaginationForData(
    props,
    pageNumQuery,
  );

  return (
    <LandingPageV2Layout {...props}>
      <Box h={50} />
      <RichTextRenderer.Component
        body={props.fields.body}
        idToLoaderData={props.idToLoaderData}
        entryLookup={landingPageV2EntryLookup}
      />
      {showPagination && (
        <Flex justifyContent={'center'} gap={2} mt={11}>
          <SelectiveSpritesheet icons={[ArrowRight, ArrowLeft]} />
          <Link
            fontWeight="semibold"
            aria-label="Go to previous page"
            analyticsName="topic-page-previous"
            disabled={currentPage === 1}
            color={currentPage === 1 ? 'neutrals70' : 'brand50'}
            href={`?page=${previousPage}`}
            opacity={currentPage === 1 ? 0.5 : 1}
            className="pagination-button"
            css={paginationStyles}
          >
            <Icon name="arrow-left" size={16} />
          </Link>
          <Link
            fontWeight="semibold"
            aria-label="Go to next page"
            analyticsName="topic-page-next"
            disabled={currentPage === lastPage}
            color={currentPage === lastPage ? 'neutrals70' : 'brand50'}
            href={`?page=${nextPage}`}
            opacity={currentPage === lastPage ? 0.5 : 1}
            css={paginationStyles}
          >
            <Icon name="arrow-right" size={16} />
          </Link>
        </Flex>
      )}
      <Box h={144} />
    </LandingPageV2Layout>
  );
};

export default LandingPageWithContext;
